@import '/node_modules/aos/dist/aos.css';

 :root {
  --spacing-small: 2em;
  --spacing-medium: 4em; }


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

@font-face {
	font-family: 'Graphik Regular';
	src: url('../../fonts/GraphikRegular.otf');
	src: url('../../fonts/GraphikRegular.otf?#iefix') format('embedded-opentype'), url('../../fonts/GraphikRegular.otf') format('otf'), url('../../fonts/GraphikRegular.otf') format('truetype');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'Graphik Semibold';
	src: url('../../fonts/GraphikSemibold.otf');
	src: url('../../fonts/GraphikSemibold.otf?#iefix') format('embedded-opentype'), url('../../fonts/GraphikSemibold.otf') format('otf'), url('../../fonts/GraphikSemibold.otf') format('truetype');
	font-weight: 600;
	font-style: normal; }

@font-face {
	font-family: 'Graphik Medium';
	src: url('../../fonts/GraphikMedium.otf');
	src: url('../../fonts/GraphikMedium.otf?#iefix') format('embedded-opentype'), url('../../fonts/GraphikMedium.otf') format('otf'), url('../../fonts/GraphikMedium.otf') format('truetype');
	font-weight: 500;
	font-style: normal; }

html {
  scroll-behavior: smooth; }

html, body {
  height: 100%;
  font-size: 100%;
  font-family: 'Graphik Regular', sans-serif;
  line-height: 1.8; }

strong {
  font-family: 'Graphik Medium'; }

h1, h2, h3, h4, h5 {
  font-family: 'Graphik Semibold', sans-serif;
  line-height: 1.2; }

h1 {
  margin: 0 0 2em 0; }

h2 {
  margin: 0 0 1em 0;
  font-size: 3em; }

h3 {
  margin: 0 0 1em 0;
  font-size: 2em; }

h4 {
  margin: 0 0 1em 0;
  font-size: 1.25em; }

p {
  margin: 0 0 2em 0;
  font-weight: 400;
  font-style: normal;

  & + h2,
  & + h3 {
    padding-top: 2em; } }

hr {
  height: 2px;
  width: 100%;
  margin: 6em 0 6em 0;
  border: 0;
  background: #f1f1f1; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

a {
  color: #7d447d;

  &[rel="external"] {
    background: url(../../images/icon-external.svg) no-repeat right top;
    background-size: .9em;
    padding-right: 1.5em;
    margin-right: .25em; } }

main, section, article, header, footer, nav {
  display: block; }


.o-container {
  max-width: 72em;
  margin: 0 auto;
  padding: 0 1em;

  &--md {
    max-width: 40em; } }

.u-img {
  &--fluid {
    width: 100%; } }

.u-margin {

  &-top {
    &--sm {
      margin-top: var(--spacing-small); }
    &--md {
      margin-top: var(--spacing-medium); } }

  &-bottom {
    &--sm {
      margin-bottom: var(--spacing-small); }
    &--md {
      margin-bottom: var(--spacing-medium); } } }

.u-padding {
  &-top {
    &--md {
      padding-top: var(--spacing-medium); } } }

.img {
  display: inline-block;
  verticle-align: middle; }

.u-text {

  &--centered {
    text-align: center; }

  &--lg {
    font-size: 1.2em; } }

.list {
  &--logos {
    li {
      display: inline-block;

      img {
        height: 2em;
        width: auto;
        margin: 0 2em 1em 0; } } }

  &--checked {
    margin: 0 0 2em 0;

    li {
      padding-left: 2.5em;
      list-style: none;
      background: url(../../images/icon-check.svg) no-repeat left top .3em;
      background-size: 1em; } } }


.sticky {
  position: fixed !important; }

section__header--work {
  position: absolute;
  top: 0;
  width: 100%; }

.section {
  position: relative;
  padding: 4em 0;

  @media only screen and (min-width: 45em) {
    padding: 10em 0; }

  &--intro {
    color: white;
    padding-top: 13em;
    // background: black url(../../images/bg-intro.jpg) no-repeat left top
    background: black;


    @media only screen and (min-width: 45em) {
      padding-top: 18em; }


    blockquote {
      font-size: 5vmax;
      line-height: 1.2;
      margin: 0;
      font-family: 'Graphik Semibold', sans-serif;

      p {
        margin: 0; } }

    cite {
      font-size: 1.25em;
      font-style: normal; } }

  &--clients {
    background: #f1f1f1; }

  &--work {
    background: black;
    border: 0;
    color: white;
    padding-top: 180px; }


  &__header {

    h2 {
      font-size: 2em; }
    &__intro {
      font-size: 1em; }

    @media only screen and (min-width: 45em) {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      padding: 6em 4em;
      padding-right: 70%;


      h2 {
        position: relative;
        font-style: italic;
        font-size: 3em;
        margin-bottom: 1em; }

      &__intro {
        font-size: 1.25em; }

      &.is-fixed {
        position: fixed; } } }


  &__content {
    padding-top: 6em;
    position: relative;


    @media only screen and (min-width: 45em) {
      padding: 6em 6em 6em calc(30% + 6em); } } }

.well {
  padding: 4em 4em 3em 4em;
  background: #f1f1f1;
  margin: 0 0 4em 0;

  &:last-child {
    margin-bottom: 0; }
  p {
    margin: 0 0 1em 0;

    &:last-child {
      margin-top: 1em; } }

  h2 {
    margin: 0 0 1em 0;
    font-size: 1em;
    font-weight: 300;
    text-transform: none; } }

.c-logo {
  font-family: 'Graphik Medium';
  color: black;
  margin-bottom: 1em;

  h1 {
    font-size: 1.2em; } }



.c-navbar {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
  background: white;
  padding: 2em 0;
  border-bottom: 2px solid #555;

  nav {
    ul {
      li {
        display: inline-block;
        margin-right: 2em;

        &:last-child {
          a {
            display: none; } }

        a {
          color: black;
          position: relative;
          display: inline-block;
          text-decoration: none;

          &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            bottom: 3px;
            background: black; } } } } } }

@media only screen and (min-width: 98em) {
  .c-navbar.is-sticky {
    border: 0;

    .c-logo {
      position: fixed;
      top: 10%;
      z-index: 100;
      color: black;

      h1 {
        margin-left: -15em;
        width: 10em;
        display: inline-block;
        text-align: right;

        span.sub {
          clip: rect(0 0 0 0);
          clip-path: inset(50%);
          height: 1px;
          overflow: hidden;
          position: absolute;
          white-space: nowrap;
          width: 1px; } } }

    nav {
      position: fixed;
      top: 10%;
      z-index: 100;

      ul {
        list-style: none;
        margin-left: -16em;
        margin-top: 3em;
        width: 10em;
        text-align: right;

        li {
          margin: 0 0 .25em 0;
          display: block;

          &:last-child {
            a {
              display: inline-block;
              margin-top: 2em; } }

          a {
            color: black;
            position: relative;
            display: inline-block;


            &:hover {
              opacity: 0.8;
              // padding-right: 0.5em
 }              // transition: all .2s linear

            &:after {
              background: black; } } } } } } }



.cards {
  list-style: none;
  margin: 0 0 4em 0;
  padding: 0; }

.card {
  background: white;
  color: black;
  padding: 2em;
  margin: 0 0 2em 0;

  @media only screen and (min-width: 45em) {
    padding: 4em; }

  &__visual {
    margin-bottom: 2em; }

  &__header {
    font-size: 1.5em; }

  &__body {
    & > *:last-child {
      margin-bottom: 0; } } }

.c-skiplink {
    position: absolute;
    top: 1rem;
    left: -9999rem;
    z-index: 102;
    background: white;
    color: black;

    &:focus {
      left: 1rem; } }

.c-label {
  background: #f1f1f1;
  display: inline-block;
  padding: .25em .75em;
  border-radius: 3px;
  font-size: 0.9em;
  position: relative;
  margin: 0 .25em .5em 0;

  &--info {
    background: #7D447D;
    color: white; }

  &--available {
    background: green;
    color: white; } }



.row {
  position: relative;
  width: 100%; }

.row {
  [class^="col"] {
    float: left;
    min-height: 0.125rem;

    @media only screen and (min-width: 45em) {
      margin-left: 2%; }

    &:first-child {
      margin-left: 0; } }
  @media only screen and (min-width: 45em) {
    &-gutter--lg [class^="col"] {
      margin-left: 8%; } } }

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: 100%; }

.col-1-sm {
  width: 4.33%; }

.col-2-sm {
  width: 12.66%; }

.col-3-sm {
  width: 21%; }

.col-4-sm {
  width: 29.33%; }

.col-5-sm {
  width: 37.66%; }

.col-6-sm {
  width: 46%; }

.col-7-sm {
  width: 54.33%; }

.col-8-sm {
  width: 62.66%; }

.col-9-sm {
  width: 71%; }

.col-10-sm {
  width: 79.33%; }

.col-11-sm {
  width: 87.66%; }

.col-12-sm {
  width: 96%; }

.row::after {
	content: "";
	display: table;
	clear: both; }

.hidden-sm {
  display: none; }

@media only screen and (min-width: 45em) {
  .col-1 {
    width: 4.33%; }

  .col-2 {
    width: 12.66%; }

  .col-3 {
    width: 21%; }

  .col-4 {
    width: 29.33%; }

  .col-5 {
    width: 37.66%; }

  .col-6 {
    width: 46%; }

  .col-7 {
    width: 54.33%; }

  .col-8 {
    width: 62.66%; }

  .col-9 {
    width: 71%; }

  .col-10 {
    width: 79.33%; }

  .col-11 {
    width: 87.66%; }

  .col-12 {
    width: 96%; }

  .hidden-sm {
    display: block; } }

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px; }

.icon {
  padding-left: 2em;
  background-position: left top;
  background-size: 1em;
  background-repeat: no-repeat;

  &--mail {
    background-image: url(../../images/icon-email.svg); } }
